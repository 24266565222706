// Section Title 
.section-title {
  padding: 50px 16px;
  display: flex;
  flex-direction: column;
  margin: auto;
  @include media-breakpoint-up(lg){
    width: 800px;
    padding: 80px 60px;
  }
}