.content-thumb {
  width: 100% !important;
  height: 420px;

  @include media-breakpoint-up(md) {
    height: 400px;
  }
  @include media-breakpoint-up(lg) {
    width: 50% !important;
    height: calc(100vh - 120px);
  }


  background-position: center;
  background-size: cover;
}

.height-50 {
  height: 80px;
}

.content-form {
  margin-left: 0;
  width: 100%;
  max-width: 100%;

  @include media-breakpoint-up(lg) {
    width: 450px;
    max-width: 450px;
    margin-left: 50px;
  }

  @include media-breakpoint-up(xl) {
    margin-left: 75px;
  }

  .form-title {
    margin-top: 30px;
    margin-bottom: 30px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 90px;
      margin-top: 0;
    }
  }

  .form-group {
    margin: 30px 0;

    .form-control {
      border-radius: 0;
      height: 50px;
      background: #f8f5f4;

      &:hover,
      &:focus {
        background: #f8f5f4;
        border-radius: 0;
        outline: none;
        box-shadow: 0 0 0;
      }
    }
  }
}

.form-title h3 {
  font-size: 40px;
}

.btn-custom-sign-in {
  border: none;
  background: none;
  color: #7f9cbb;
  font-weight: 500;
  border-bottom: 2px solid #7f9cbb;
  padding: 7px 0;
  font-size: 16px;

  &:hover,
  &:focus {
    outline: none;
    box-shadow: 0 0 0;
  }
}
.login{
  .content-thumb{
    @include media-breakpoint-up(md) {
      height: 545px;
    }
    @include media-breakpoint-up(lg) {
      height: calc(100vh - 120px);
    }
  }
}
