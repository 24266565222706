.stores .content-thumb {
  background-position: center bottom;
}
.stores .content-form{
  min-width: 50%;
}
.store-logos {
  display: flex;
  flex-wrap: wrap;
  .single-logo{
      width: 325px;
      padding: 40px 70px;
      box-shadow: 0 0 1px #999;
      margin-right: 28px;
      img{
        width: 100%;
      }
  }
}
