.about-wrapper {
  display: flex;
  align-items: center;
  height: 100vh;
  .img-fluid {
    width: 100%;
    height: 385px;
    object-fit: cover;
  }
}


// Our Team
.our-team-wrapper {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // height: 100vh;
  .container-fluid {
    height: 100vh;
    display: flex;
    align-items: center;
  }

}

.content-form span {
  font-size: 30px;
  line-height: 35px;
  margin-bottom: 20px;
  display: block;
}
.main-content.about .form-title{
  margin-bottom: 30px;
}
.main-content.about p{
  font-size: 15px;
}

.about{
  .content-thumb{
    @include media-breakpoint-up(md) {
      height: 650px;
    }
    @include media-breakpoint-up(lg) {
      height: calc(100vh - 120px);
    }
  }
}
.navigate a{

}
