// Breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1500px
);

// Spacer
$spacer: 1rem !default;
$spacers: (
  (
    0: 0,
    1: ($spacer * .3125),
    2: ($spacer * .625),
    3: $spacer * .9375,
    4: ($spacer * 1.25),
    5: ($spacer * 1.5625),
    6: ($spacer * 1.875),
    7: ($spacer * 3.125),
    8: ($spacer * 4.0625)
  )
);

// Color system

$gray-100:                  #f5f6fa !default;
$gray-200:                  #f0f1f5 !default;
$gray-300:                  #b6bdca !default;
$gray-900:                  #1e1f1a !default;

// Colors
$primary:                   #1573a6 !default;
$dark:                      $gray-900 !default;
$light:                     $gray-100 !default;
$white:                     #ffffff !default;
$text-color:                #8a909d !default;

$theme-colors: (
  (
    "primary":                $primary,
    "light":                  $light

  )
);


//Border radius

$border-radius: .25rem !default;

// Fonts

$font-family-montserrat:        'Montserrat', sans-serif !default;
$font-family-roboto:        'Roboto', sans-serif !default;
$font-family-roboto-mono:       'Roboto Mono', monospace !default;
$font-family-syne:       'Syne', sans-serif !default;

$font-family-base:              $font-family-syne !default;
$font-family-secondary:         $font-family-roboto !default;

$font-size-base:                1rem !default;
$font-size-sm:                  ($font-size-base * .8) !default;
$font-weight-medium:            400 !default;

