h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-syne;
}

.container-fluid {
  @include media-breakpoint-up(xl){
    padding-left: 60px;
    padding-right: 60px;
  }
}


.main-wrapper {
  @include media-breakpoint-up(xl){
    // padding-top: 150px;
  }
}
.text-info {
  color: #9bc1e5 !important;
}
#root{
  overflow-x: hidden;
}
a{
  color: #7f9cbb
}
a:hover,a:focus{
  text-decoration: none;
  color: #7f9cbb;
}
