.custom-nav-slider {
  .swiper-custom-button {
    border: 1px solid $primary;
    color: $primary;
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    &:after {
      display: none;
    }
    &:active,
    &:hover {
      box-shadow: unset !important;
    }
  }
}
.salon-page .swiper-pagination-bullets .swiper-pagination-bullet{
  color: #fff !important;
}
.salon-page .banner-text-image{
  display: none;
}
.white .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets:before {
  position: absolute;
  right: 0;
  content: "";
  width: calc(100% - 300px);
  background: #fff;
  height: 1px;
  top: 49%;
  right: 150px;
  margin-top: 10px;
}
