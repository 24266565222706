.salon-page {
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 50px;

  @include media-breakpoint-up(lg) {
    padding-bottom: 0;
  }


  .content-thumb {
    .swiper-container {
      //height: 320px;

      //@include media-breakpoint-up(lg) {
      //  height: calc(100vh - 120px);
      //
      //}
    }
    .slider-bg-holder{
      height: 320px;
      @include media-breakpoint-up(md){
        height: 400px;
      }
      @include media-breakpoint-up(lg){
        height: calc(100vh - 120px);
      }
    }

  }



  .address {
    p {
      margin-bottom: 0px;
    }
  }
}


.loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, .95);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #666;
}
.address{
  font-family: "Roboto", sans-serif;
}
.address p{
  font-size: 14px;
}
.salon-page .btn-custom-sign-in{
  font-family: "Roboto", sans-serif;
}
