//Card Team

.card-team {
  text-align: center;
  padding-top: 30px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 30px;
  border: 0;
//   -webkit-box-shadow: -1px 6px 9px 0px rgba(0,0,0,0.21);
// -moz-box-shadow: -1px 6px 9px 0px rgba(0,0,0,0.21);
// box-shadow: -1px 6px 9px 0px rgba(0,0,0,0.21);
  .card-img-top {
    max-width: 100px;
    height: auto;
    border-radius: 50%;
    margin: auto;
  }
  .list-unstyled {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li {
      width: 20px;
      margin-right: 10px;
    }
  }
}