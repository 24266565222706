.salon-block {
  .single-salon-team {
    min-width: 26%;
    margin-bottom: 30px;
    @include media-breakpoint-up(md){
      min-width: 33%;
    }
    @include media-breakpoint-up(lg){
      min-width: 26%
    }

    .thumb {
      img {
        width: 70px;
        height: 70px;
        object-fit: cover;
      }
    }

    .introduction {
      margin-left: 20px;
    }
  }
}

.main-content.about.team .content-form {
  @include media-breakpoint-up(xl) {
    min-width: 50%;
  }
}

.carousel-indicators li {
  text-indent: inherit;
  font-size: 19px;
}

.carousel-indicators {
  justify-content: left;
  margin-left: 0;
  bottom: -30px;
}

.introduction img {
  width: 17px;
  margin-right: 7px;
  margin-top: 15px;
}
.carousel-indicators li{
  font-family: "Roboto", sans-serif;
  font-size: 15px;
}
.carousel.slide {
  min-height: 190px;
}
